import { Github, Globe, Linkedin, Mail, MapPin, Phone } from 'lucide-react';

export function Main() {
  const experiences = [
    {
      title: "Software Development Engineer",
      company: "Truste Web Services/ TrustArc",
      period: "2022 - Present",
      description: "Designed and managed both modern and legacy cookie consent platforms using Google Web Toolkit, Angular, and Spring Boot.",
      achievements: [
        "Maintained client-facing legacy preference portal built with Google Web Toolkit",
        "Led migration to modern design system using PrimeNG and spearheaded Angular upgrade from version 11 to 15",
        "Sole developer and maintainer of mobile consent platform, modernizing UI with CSS and Svelte",
        "Managed platform's API and SDKs across Spring Boot, Flutter, React Native, iOS, and Kotlin/Java",
        "Developed and implemented functional and end-to-end unit tests using Jest, Karma, and Cypress",
        "Integrated pre-commit hooks for code linting and commit message enforcement",
        "Mentored junior developers and provided regular code reviews",
        "Implemented micro frontend architecture using component decoupling approach"
      ]
    },
    {
      title: "DevOps Engineer",
      company: "Ray Business Technologies",
      period: "2021 - 2022",
      description: "Responsible for deploying and optimizing Boomi software on Azure Kubernetes Service (AKS).",
      achievements: [
        "Automated deployment and management of Azure AKS infrastructure using Terraform",
        "Implemented monitoring solution using Prometheus as Kubernetes DaemonSet",
        "Configured Grafana to visualize metrics through dashboards",
        "Conducted load testing using JMeter by simulating multiple users",
        "Prepared daily status reports including system uptime and performance metrics"
      ]
    },
    {
      title: "Software Engineer",
      company: "Truste Web Services/ TrustArc",
      period: "2020 - 2021",
      description: "Implemented bug fixes and new features for cookie platform.",
      achievements: [
        "Contributed as Front-End developer on individual rights management platform using Angular",
        "Refactored, rewritten, and enhanced legacy codebase for cookie crawling platform"
      ]
    },
    {
      title: "Software Engineer",
      company: "Amihan Global Strategies",
      period: "2018 - 2020",
      description: "Collaborated on multiple projects involving insurance and banking applications.",
      achievements: [
        "Developed backend services for integrating Hyperledger Indy and Fabric",
        "Improved storage of root hashes and blobs using MongoDB, GridFS, and Python",
        "Implemented upload service for scanned documents using TensorFlow's OCR",
        "Created Dockerfiles, Kubernetes Helm charts, and NGINX configurations",
        "Designed user interfaces using Figma and implemented them with Angular and Ionic"
      ]
    },
    {
      title: "Test Automation Engineer",
      company: "EDMI Limited Inc.",
      period: "2016 - 2017",
      description: "Developed automation portal for testing electric meter firmware.",
      achievements: [
        "Developed automation portal integrating with TestLink using Python Flask, HTML, jQuery, and CSS",
        "Created automation clients running as services on remote desktops",
        "Implemented script execution and result posting using PyWinAuto, AutoOCR, and Robot Framework"
      ]
    },
    {
      title: "Professional Services Consultant",
      company: "NCR Development Center",
      period: "Apr 2016 - Dec 2016",
      description: "Developed C# program for modular automation script execution.",
      achievements: [
        "Developed modular script library using Java and Selenium",
        "Created runner program with WinForms UI in C# with IntelliSense support",
        "Assisted manual testers in creating test script scenarios using Gherkin/Cucumber format"
      ]
    }
  ];

  return (
    <div className="max-w-5xl mx-auto p-8 bg-white">
      {/* Header */}
      <header className="mb-8">
        <h1 className="text-4xl font-bold text-gray-800">Cyrus Zandro R. Hiyas</h1>
        <h2 className="text-2xl text-blue-600 font-semibold mt-2">Senior Software Development Engineer</h2>
        
        {/* Contact Info */}
        <div className="mt-4 flex flex-wrap gap-4 text-gray-600">
          <div className="flex items-center gap-2">
            <MapPin size={16} />
            <span>Lapu-Lapu City, Cebu, 6015, Philippines</span>
          </div>
          <div className="flex items-center gap-2">
            <Mail size={16} />
            <span>czhiyas@gmail.com</span>
          </div>
          <div className="flex items-center gap-2">
            <Phone size={16} />
            <span>+63 949 160 0551</span>
          </div>
          <div className="flex items-center gap-2">
            <Globe size={16} />
            <span>geekybarista.com</span>
          </div>
          <div className="flex items-center gap-2">
            <Github size={16} />
            <span>github.com/drupi0</span>
          </div>
          <div className="flex items-center gap-2">
            <Linkedin size={16} />
            <span>linkedin.com/in/drupi0</span>
          </div>
        </div>
      </header>

      {/* Professional Summary */}
      <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-800 border-b-2 border-blue-500 pb-2 mb-4">Professional Summary</h2>
        <p className="text-gray-700 leading-relaxed">
          Dynamic Senior Software Engineer with 8+ years of experience specializing in full-stack development and front-end architecture. 
          Proven expertise in Angular development and cloud technologies, with a track record of delivering high-performance, 
          accessible applications for the financial and healthcare sectors. Demonstrated leadership in modernizing legacy systems 
          and implementing cutting-edge technologies while mentoring junior developers.
        </p>
      </section>

      {/* Core Competencies */}
      <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-800 border-b-2 border-blue-500 pb-2 mb-4">Core Competencies</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {[
            'Full-Stack Development',
            'Front-End Architecture',
            'Cloud Infrastructure',
            'Team Leadership & Mentoring',
            'Performance Optimization',
            'Accessibility (WCAG)',
            'Micro Frontend Architecture',
            'DevOps & CI/CD',
            'Test Automation',
            'Mobile Development',
            'System Architecture',
            'Blockchain Integration'
          ].map((skill, index) => (
            <div key={index} className="bg-gray-50 p-3 rounded-lg text-gray-700">
              {skill}
            </div>
          ))}
        </div>
      </section>

      {/* Professional Experience */}
      <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-800 border-b-2 border-blue-500 pb-2 mb-4">Professional Experience</h2>
        
        {experiences.map((exp, index) => (
          <div key={index} className="mb-6">
            <div className="flex justify-between items-baseline">
              <h3 className="text-lg font-semibold text-gray-800">{exp.title}</h3>
              <span className="text-gray-600">{exp.period}</span>
            </div>
            <div className="text-blue-600 font-semibold">{exp.company}</div>
            <p className="mt-2 text-gray-700">{exp.description}</p>
            <ul className="mt-2 list-disc list-inside text-gray-700">
              {exp.achievements.map((achievement, i) => (
                <li key={i} className="ml-4">{achievement}</li>
              ))}
            </ul>
          </div>
        ))}
      </section>

      {/* Technical Expertise */}
      <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-800 border-b-2 border-blue-500 pb-2 mb-4">Technical Expertise</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Frontend</h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Angular (Expert) | React | Svelte | Ionic</li>
              <li>TypeScript/JavaScript | HTML5/CSS3</li>
              <li>State Management (NgRx, Redux)</li>
              <li>UI Frameworks (PrimeNG, Bootstrap, Tailwind)</li>
              <li>Mobile (Swift, Android, React Native)</li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Backend</h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Node.js/Express | Spring Boot</li>
              <li>Python (Django/Flask) | Java | GoLang</li>
              <li>REST API Design | Microservices</li>
              <li>Blockchain (Hyperledger Indy, Fabric)</li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Cloud & DevOps</h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>AWS/Azure/GCP Services</li>
              <li>Kubernetes | Docker | Terraform</li>
              <li>CI/CD (GitHub Actions, GitLab)</li>
              <li>Linux/Shell Scripting</li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Testing & Automation</h3>
            <ul className="list-disc list-inside text-gray-700">
              <li>Jest | Karma | Cypress</li>
              <li>Robot Framework | Selenium</li>
              <li>PyWinAuto | AutoOCR</li>
              <li>JMeter | TestLink</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Notable Project */}
      <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-800 border-b-2 border-blue-500 pb-2 mb-4">Notable Project</h2>
        <div>
          <h3 className="text-lg font-semibold text-gray-800">Medical Laboratory System | Multihealth Dynamic Solutions Inc.</h3>
          <ul className="mt-2 list-disc list-inside text-gray-700">
            <li>Architected scalable system using Angular 17, Spring Boot, and Keycloak</li>
            <li>Implemented WCAG-compliant UI improving accessibility scores by 95%</li>
            <li>Deployed secure authentication system meeting healthcare compliance requirements</li>
            <li>Utilized Angular Signals for efficient state management</li>
          </ul>
        </div>
      </section>
    </div>
  );
};
