import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { Main } from './pages/main/Main';
import { Header } from './components/header/Header';

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
